.form_style {
  position: relative;
  outline: 0px;
  margin: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 24px;
  background-color: rgb(255, 255, 255);
  border-width: 1px 0px;
  border-style: solid;
  border-color: rgb(231, 235, 240);
  border-image: initial;
  width: 35%;
}

.border-style {
  border-radius: 10px;
  border-left-width: 1px;
  border-right-width: 1px;
}